<template>
    <div class="add-polt">
        <van-form class="form-container">
            <van-field
                v-model="plotInfo.name"
                name="name"
                label="设备名称"
                placeholder="请输入设备名称"
                :rules="[{ required: true}]"
                disabled
            />
            <van-field
                v-model="plotInfo.imei"
                name="IMEI"
                label="IMEI"
                placeholder="请输入IMEI"
                :rules="[{ required: true}]"
                disabled
            />
            <van-field
                v-model="plotInfo.parkName"
                name="parkName"
                label="园区"
                readonly
                placeholder="请选择园区"
                :rules="[{ required: true}]"
                disabled
            />
            <van-field
                v-model="plotInfo.massifName"
                name="massifName"
                label="地块名称"
                placeholder="请输入地块名称"
                :rules="[{ required: true}]"
                disabled
            />
            <van-field
                v-model="plotInfo.phone"
                name="phone"
                label="联系电话"
                :placeholder="isAdmin ? '请正确手机号码' : ''"
                disabled
            />
            <van-field name="switch" label="可租借">
                <template #input>
                    <van-switch v-model="plotInfo.isLease" size="20" @change="changeLease" />
                </template>
            </van-field>
        </van-form>
    </div>
</template>

<script>
import {listTreeOrg } from '@/api/plotManagement';
import { addMassif, modifyMassif} from '@/api/plotManagement';
import {getToken,getOrgId,getUserType } from '@/utils/cookie'
import { editDevicePort } from '@/api/agriculturalMachinery/deviceManagement';
export default {
    data() {
        return {
            isAdd: true,//判断是新增还是编辑
            plotInfo: {
                massifName: '', // 地块名称
                parkName: '',//园区名称
                parkId: '', //园区id
                address: '', // 地块地址
                menuPid: '', // 父级地块ID
                contacts: '', // 联系人
                phone: '', // 手机号
                remark: '', // 描述
                describe: '',//经纬度
                updater: '1',//是否启用
                crop: '',//作物名称
                description: '',//作物描述
            },
            switchChecked: true,//是否启用
            show: false,//是否显示选择园区
            showAddress: false,//是否选择地理位置
            activeId: 1,
            activeIndex: 1,
            actions: [],
            map: null,//地图
            mapSearch: '',
            isAdmin: true,//是否是管理员
        }
    },
    mounted() {
        if (this.$route.params) {
            this.plotInfo = this.$route.params;
        }
        this.$nextTick(() => {
            this.isAdmin = getUserType() == 1 ? true : false;
        });
        this.listAllPark()
    },
    methods: {
        changeLease() {
            editDevicePort(this.plotInfo).then( res => {
                if (res.code == 200) {
                    // this.getTableList();
                    this.$toast('修改成功');
                } else {
                    this.$toast(res.message);
                }
            });
        },
        /** 查询所有园区 */
        listAllPark() {
            listTreeOrg({
                orgId: getOrgId(),//当前查询页面
            }).then( res => {
                if( res.code === 200 ) {
                    res.data.forEach((item, index) => {
                        if (item) {
                            item.name = item.orgName;
                        }
                    });
                    this.actions = res.data || [];
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped>
    .add-polt {
        height: 100%;
        background-color: #fff;
        .form-container {
            padding: 8px;
            .form-bottom {
                position: fixed;
                left: 12px;
                bottom: 10px;
                width: 346px;
            }
        }
    }
</style>
<style lang="scss">
    .add-polt {
        .van-popup {
            width: 100%;
            height: 100%;
            .map-container {
                width: 100%;
                height: 600px;
                margin: 0 auto;
                border-radius: 8px;
                .map {
                    width: 100%;
                    height: 100%;
                    // margin-top: 30px;
                    border-radius: 8px;
                }
            }
            .van-popup__close-icon {
                top: 5px;
                right: 5px;
                color: #a7a9af;
            }
            .map-search {
                position: absolute;
                top: 10px;
                left: 10px;
                width: 346px;
            }
            .van-search {
                background-color: transparent;
            }
        }
        .btn-save {
            position: fixed;
            bottom: 10px;
            left: 12px;
            width: 346px;
        }
    }
    .tangram-suggestion-main {
        left: 25px !important;
        width: 310px !important;
        z-index: 999999;
    }
</style>